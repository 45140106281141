import { graphql, PageProps } from 'gatsby'
import React, { useMemo } from 'react'
import BlogContainer from '../components/BlogContainer'
import BlogPostCard from '../components/BlogPostCard'
import BlogHeader from '../components/BlogHeader'
import BlogLinks from '../components/BlogLinks'
import BlogList from '../components/BlogList'
import BlogMorePosts from '../components/BlogMorePosts'
import { BlogPageContext } from './BlogHomePage.context'
import { Unpacked } from '../utils/Unpacked'
import { BlogPostsQuery } from '../_generated/codegen/graphqlTypes'
import notEmpty from '../utils/notEmpty'
import useTagsQuery from '../hooks/useTagsQuery'
import useCategoriesQuery from '../hooks/useCategoriesQuery'
import { Helmet } from 'react-helmet'

const useFeatured = (data: BlogPostsQuery): IBlogPostFeatured | undefined => {
  return useMemo(() => {
    return data.strapi.featured ?? undefined
  }, [data])
}

const useItems = (data: BlogPostsQuery): IBlogPostItem[] => {
  return useMemo(() => data.strapi.list?.filter(notEmpty) ?? [], [data])
}

const useTops = (data: BlogPostsQuery): IBlogPostTop[] => {
  return useMemo(() => data.strapi.top?.filter(notEmpty) ?? [], [data])
}

const BlogPage: React.FC<PageProps<BlogPostsQuery, BlogPageContext>> = ({
  data,
  pageContext,
}) => {
  const tags = useTagsQuery()
  const categories = useCategoriesQuery()
  const featured = useFeatured(data)
  const items = useItems(data)
  const tops = useTops(data)

  return (
    <>
      {!pageContext.category &&
        !pageContext.tag &&
        pageContext.currentPage === 0 && (
          <Helmet>
            <meta
              name="description"
              title="Save, meilleure enseigne 2022 et 2023 !"
              content={`Save, leader de la réparation avec près de 200 magasins en France. Save est partenaire avec les grands constructeurs comme Samsung, Apple, Huawei et Xiaomi !`}
            />
          </Helmet>
        )}

      <BlogContainer>
        {featured && (
          <BlogHeader>
            <BlogPostCard item={featured} />
            {categories.length > 0 && (
              <BlogLinks
                className="BlogLinks_Categories"
                items={categories}
                type="category"
              />
            )}
            {tags.length > 0 && (
              <BlogLinks className="BlogLinks_Tags" items={tags} type="tag" />
            )}
          </BlogHeader>
        )}
        <BlogList
          items={items}
          currentPage={pageContext.currentPage}
          numPages={pageContext.numPages}
          category={pageContext.category}
          tag={pageContext.tag}
        />
        <BlogMorePosts
          title="Les articles les plus populaires :"
          items={tops}
        />
      </BlogContainer>
    </>
  )
}

export type IBlogPostFeatured = NonNullable<
  NonNullable<BlogPostsQuery['strapi']['featured']>
>

export type IBlogPostItem = NonNullable<
  Unpacked<NonNullable<BlogPostsQuery['strapi']['list']>>
>

type IBlogPostTop = NonNullable<
  Unpacked<NonNullable<BlogPostsQuery['strapi']['top']>>
>

export const query = graphql`
  fragment uploadFileFields on Strapi_UploadFile {
    url
    name
  }

  fragment topFields on Strapi_BlogPost {
    id
    slug
    title
    tags {
      id
      name
      slug
    }
    category {
      id
      name
      slug
    }
  }

  fragment postFields on Strapi_BlogPost {
    ...topFields
    published_at
    description
  }

  query BlogPosts(
    $start: Int!
    $limit: Int!
    $withFeatured: Boolean = false
    $featuredId: ID = "-1"
    $where: Strapi_JSON
  ) {
    strapi {
      featured: blogPost(id: $featuredId) @include(if: $withFeatured) {
        ...postFields
        cover {
          ...uploadFileFields
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 650)
            }
          }
        }
        author {
          id
          extra {
            lastname
            firstname
            avatar {
              url
              imageFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100, width: 50)
                }
              }
            }
          }
        }
      }
      list: blogPosts(
        start: $start
        limit: $limit
        sort: "published_at:desc"
        where: $where
      ) {
        ...postFields
        cover {
          ...uploadFileFields
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 250)
            }
          }
        }
      }
      top: blogPosts(
        start: 0
        limit: 10
        sort: "published_at:desc"
        where: { top: true }
      ) {
        ...topFields
        cover {
          ...uploadFileFields
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100, width: 180)
            }
          }
        }
      }
    }
  }
`

export default BlogPage
