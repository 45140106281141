import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: #ffffff;
`

const Child = styled.div`
  padding: 0 20px;
  max-width: 940px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 11px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 24px;
  }
`

interface Props {
  children?: React.ReactNode
}

const BlogContainer: React.FC<Props> = ({ children }) => (
  <Container>
    <Child>{children}</Child>
  </Container>
)

export default BlogContainer
